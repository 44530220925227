








import { Component, Vue } from "vue-property-decorator";
import PerfilPublico from "@/components/Common/PerfilPublico.vue";

@Component({
  components: {
    PerfilPublico
  }
})
export default class PerfilPublicoView extends Vue {}
