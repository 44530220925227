


































import { Component, Vue } from "vue-property-decorator";

@Component
export default class PerfilPublico extends Vue {
  private columns: any = [
    {
      name: "nombre",
      required: true,
      label: "Nombre",
      align: "left",
      field: (row) => row.nombre,
      sortable: true,
    },
    {
      name: "rut",
      align: "center",
      label: "Rut",
      sortable: true,
      field: (row) => row.rut,
    },
  ];
  private loading: boolean = false;
  private cod: any = "";
  private tarjeta: null | any = "";
  private dataCargas: any = [];
  private data = {
    id: "",
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    rut: "",
    codigo: "",
    qr: new Image(),
    tipoVecino: 0,
    cargas: [],
  };
  private cardImage = new Image();
  private canvas: any = null;

  private mounted() {
    if (
      this.$router.currentRoute.params.num &&
      this.$router.currentRoute.params.num != ""
    ) {
      this.cod = this.$router.currentRoute.params.num;
      this.$q.loading.show({
        delay: 100, // ms
      });
      this.$axios
        .get("perfil/publico?num=" + this.cod)
        .then((res) => {
          Object.assign(this.data, res.data);
          const qr = new Image();
          if (this.data.qr) {
            qr.src = `data: ${this.data.qr}`;
            this.data.qr = qr;
            this.cardImage.src = require("@/assets/tarjeta.png");
          }
          this.dataCargas = this.data.cargas;
          this.loading = true;
        })
        .catch((error) => {
          const message = error.response.data.message;
          if (message == "NO_TARJETA_DATA") {
            this.$swal({
              icon: "error",
              title: "Información",
              text:
                "No se ha podido encontrar información de la tarjeta ingresada",
              confirmButtonText: this.$t("buttons.accept").toString(),
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.$router.push({ name: "Home" });
              }
            });
          } else if (message == "NO_VECINO") {
            this.$swal({
              icon: "error",
              title: "Información",
              text: "Ha ocurrido un error, intentelo más tarde.",
              confirmButtonText: this.$t("buttons.accept").toString(),
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.$router.push({ name: "Home" });
              }
            });
          } else if (message == "NO_VINCULO") {
            this.$swal({
              icon: "error",
              title: "Información",
              text: "Ha ocurrido un error, intentelo más tarde.",
              confirmButtonText: this.$t("buttons.accept").toString(),
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.$router.push({ name: "Home" });
              }
            });
          } else if (message == "INVALID_PARAM") {
            this.$swal({
              icon: "error",
              title: "Información",
              text: "Ha ocurrido un error, intentelo más tarde.",
              confirmButtonText: this.$t("buttons.accept").toString(),
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.$router.push({ name: "Home" });
              }
            });
          } else {
            this.$swal({
              icon: "error",
              title: "Información",
              text: "Ha ocurrido un error, intentelo más tarde.",
              confirmButtonText: this.$t("buttons.accept").toString(),
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.$router.push({ name: "Home" });
              }
            });
          }
        })
        .finally(() => {
          this.$q.loading.hide();
          if (this.data.qr) {
            this.canvas = document.getElementById("myCanvas") as any;
            this.generarTarjeta();
          }
        });
    }
  }

  private async generarTarjeta() {
    this.$q.loading.show();
    await new Promise((resolve) =>
      setTimeout(() => {
        this.printImage2();
      }, 100)
    );
  }

  private async printImage2() {
    let formattedRut = this.data.rut
      .split("-")[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    formattedRut = formattedRut + "-" + this.data.rut.split("-")[1];
    formattedRut = formattedRut.toUpperCase();

    const canvas = document.getElementById("myCanvas") as any;

    if (canvas && this.data.qr.complete) {
      canvas.width = 750;
      canvas.height = 1456;
      const ctx = canvas.getContext("2d");
      const localCardImage = new Image();
      localCardImage.src = require("../../assets/tarjeta.png");
      localCardImage.onload = async () => {
        await ctx.drawImage(localCardImage, 0, 0);

        ctx.font = "bold 40px Fira Sans";
        ctx.fillStyle = "#48297D";
        ctx.textAlign = "center";
        ctx.fillText(this.data.nombre.toUpperCase(), canvas.width / 2, 872);
        ctx.fillText(
          this.data.apellidoPaterno.toUpperCase() +
            " " +
            this.data.apellidoMaterno.toUpperCase(),
          canvas.width / 2,
          910
        );
        ctx.fillText(formattedRut, canvas.width / 2, 963);
        ctx.drawImage(
          this.data.qr,
          0,
          0,
          this.data.qr.width,
          this.data.qr.height,
          canvas.width / 4 + 55,
          500,
          this.data.qr.width - 50,
          this.data.qr.height - 50
        );
        ctx.font = "bold 40px Fira Sans";
        ctx.fillStyle = "#000000";
        if (this.data.codigo) {
          ctx.fillText(
            this.data.codigo,
            canvas.width / 2 + 5,
            330 + (this.data.qr.height * 0.75) / 2 + 360
          );
        } else {
          ctx.fillText(
            "1234567891",
            canvas.width / 2 + 5,
            330 + (this.data.qr.height * 0.75) / 2 + 360
          );
        }
        this.tarjeta = canvas.toDataURL("image/jpg");
        this.$q.loading.hide();
      };
    }
  }

  private printImage() {
    let formattedRut = this.data.rut
      .split("-")[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    formattedRut = formattedRut + "-" + this.data.rut.split("-")[1];
    formattedRut = formattedRut.toUpperCase();
    if (this.canvas && this.data.qr.complete) {
      this.canvas.width = 750;
      this.canvas.height = 1456;
      const ctx = this.canvas.getContext("2d");
      ctx.drawImage(this.cardImage, 0, 0);
      ctx.font = "bold 40px Fira Sans";
      ctx.fillStyle = "#48297D";
      ctx.textAlign = "center";
      ctx.fillText(this.data.nombre.toUpperCase(), this.canvas.width / 2, 872);
      ctx.fillText(
        this.data.apellidoPaterno.toUpperCase() +
          " " +
          this.data.apellidoMaterno.toUpperCase(),
        this.canvas.width / 2,
        910
      );
      ctx.fillText(formattedRut, this.canvas.width / 2, 963);
      ctx.drawImage(
        this.data.qr,
        0,
        0,
        this.data.qr.width,
        this.data.qr.height,
        this.canvas.width / 4 + 55,
        500,
        this.data.qr.width - 50,
        this.data.qr.height - 50
      );
      ctx.font = "bold 40px Fira Sans";
      ctx.fillStyle = "#000000";
      if (this.data.codigo) {
        ctx.fillText(
          this.data.codigo,
          this.canvas.width / 2 + 5,
          330 + (this.data.qr.height * 0.75) / 2 + 360
        );
      } else {
        ctx.fillText(
          "1234567891",
          this.canvas.width / 2 + 5,
          330 + (this.data.qr.height * 0.75) / 2 + 360
        );
      }

      const element = document.createElement("a");
      element.style.display = "none";
      element.setAttribute("href", this.canvas.toDataURL("image/jpeg"));
      element.setAttribute("download", "tarjeta.png");
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }
}
